define("discourse/plugins/dsc-hide-post-toggle/discourse/components/hide_post_button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _ajax, _ajaxError, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addSetting = addSetting;
  _exports.default = void 0;
  class toggleHidePostButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get isPostHidden() {
      const array_of_ghostmode_posts = this.appEvents.siteSettings.ghostmode_posts.split("|");
      const postIdstr = this.args.post.id.toString();
      const isPostHidden = array_of_ghostmode_posts.includes(postIdstr);
      return isPostHidden;
    }
    get icon() {
      return this.isPostHidden ? "far-eye-slash" : "far-eye";
    }
    get title() {
      return this.isPostHidden ? "Hide Post" : "Show Post";
    }
    //get className() {
    //return this.isPostHidden
    //? "button.topic_hidden custom-class-hidden"
    //: "button.topic_visible custom-class-visible";
    //}
    get className() {
      return this.isPostHidden ? "btn no-text btn-icon button.topic_hidden custom-class-hidden btn-flat" : "btn no-text btn-icon button.topic_visible custom-class-visible btn-flat";
    }
    toggleHidePost() {
      //console.log("Args",this.args.post.user['username'],this.args.post.cooked)
      //console.log("Post",this.args.post.id,this.appEvents)
      //console.log("Site Settings Posts",this.appEvents.siteSettings.ghostmode_posts)
      const array_of_ghostmode_posts = this.appEvents.siteSettings.ghostmode_posts.split("|");
      const postIdstr = this.args.post.id.toString();
      const isPostHidden = array_of_ghostmode_posts.includes(postIdstr);
      //console.log("Site Settings ",postIdstr,isPostHidden)
      const msg = this.args.post.cooked;
      let trimmedMsg = msg.substring(3, msg.length - 4);
      let first25trimmedMsg = trimmedMsg.substring(0, 25);
      //console.log("Msg",first25trimmedMsg)
      if (isPostHidden) {
        removeSetting(this.args.post.id, this.appEvents);
        alert(`Username : ${this.args.post.user['username']}\nPost Id : ${this.args.post.id} Removed\nPost : ${first25trimmedMsg}`);
        location.reload();
      } else {
        addSetting(this.args.post.id, this.appEvents);
        alert(`Username : ${this.args.post.user['username']}\nPost Id : ${this.args.post.id} Added\nPost : ${first25trimmedMsg}`);
        location.reload();
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "toggleHidePost", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
      <DButton
        class={{this.className}}
        ...attributes
        @action={{this.toggleHidePost}}
        @icon={{this.icon}}
        @title={{this.title}}
      />
    
    */
    {
      "id": "embg6+lX",
      "block": "[[[1,\"\\n  \"],[8,[32,0],[[16,0,[30,0,[\"className\"]]],[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"toggleHidePost\"]],[30,0,[\"icon\"]],[30,0,[\"title\"]]]],null],[1,\"\\n\"]],[\"&attrs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/dsc-hide-post-toggle/discourse/components/hide_post_button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = toggleHidePostButton;
  function addSetting(postId, appEvents) {
    const newGhostmodePosts = `${appEvents.siteSettings.ghostmode_posts ? appEvents.siteSettings.ghostmode_posts + "|" : ""}${postId}`;
    return (0, _ajax.ajax)(`/admin/site_settings/ghostmode_posts`, {
      type: "PUT",
      data: {
        ghostmode_posts: newGhostmodePosts
      }
    }).then(response => {
      // eslint-disable-next-line no-console
      console.log(response);
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }
  function removeSetting(postId, appEvents) {
    const currentGhostmodePosts = appEvents.siteSettings.ghostmode_posts || "";
    // eslint-disable-next-line no-unused-vars
    const currentPostIds = currentGhostmodePosts.split("|");
    // Remove the postId to delete
    const newGhostmodePosts = appEvents.siteSettings.ghostmode_posts.replace(new RegExp(`\\|${postId}`, "g"), "");
    return (0, _ajax.ajax)(`/admin/site_settings/ghostmode_posts`, {
      type: "PUT",
      data: {
        ghostmode_posts: newGhostmodePosts
      }
    }).then(response => {
      // eslint-disable-next-line no-console
      console.log(response);
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }
});